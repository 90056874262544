import { daEventCenter } from 'public/src/services/eventCenter'
import { htmlDecode, template } from '@shein/common-function'
import dprInsertMixins from 'public/src/pages/components/custom-content/dprInsertMixins.js'
import { transformImg } from '@shein/common-function'
import _ from 'lodash'

/**
 * 处理更新以及更新属性接口异常
 * @param res 接口返回信息
 * @param language
 * @param item 当前更新的商品
 * @return Object
 */
export const handleUpdateAndUpdateAttrErr = (res = {}, language = {}, item = {} )=> {
  let errorMessage = ''
  if (res.code == 300402) {
    //限时买折超过限购数
    errorMessage = language.SHEIN_KEY_PC_15071
  } else if (['200401', '500301'].indexOf(res.code + '') >= 0) {
    //闪购限购
    errorMessage = language.SHEIN_KEY_PC_14661
  } else if (['300714', '500302', '500306', '302444', '300417', '500303'].includes(res.code + '')) {
    // let proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.type_id == 10 || promo.type_id == 11)
    let id = item.promotionGroupId || ''
    errorMessage = template(res?.info?.limitCount, res?.info?.resultLimit, res?.info?.remainNum, htmlDecode({ text: res.tips }))
    if (id) {
      cartEmphasizePromotion(id, { shake: true, changeColor: true, scroll: true })
    }
    if(errorMessage) {
      daEventCenter.triggerNotice({
        daId: '1-7-7-13',
      })
    }
  } else if (res.code == 500304 || res.code == 500305) { //打折限购  500304: 单品限购     500305：订单限购

    let proInfo = item.product.product_promotion_info && item.product.product_promotion_info.filter(promo => promo.typeId == 24)
    let id = proInfo[0] && +proInfo[0].promotion_id
    if(id){
      cartEmphasizePromotion(id, { shake: true, changeColor: true, scroll: true })
    }
  } else if (res.code == 300405) {
    errorMessage = template(item.product.limit_count, htmlDecode({ text: language.SHEIN_KEY_PC_14560 }))
  } else if (['302411', '302412', '300415'].includes(res.code)) {
    errorMessage = res.tips
  } else if ( res.code == 300470 ) {
    errorMessage = res.tips.replace('{0}', res.info?.single_row_capacity_limit)
  } else {
    errorMessage = res.tips || language.SHEIN_KEY_PC_15805
  }
  return { msg: errorMessage, times: ['500302', '500306', '300417', '500303'].includes(res.code + '') ? 4000 : 0 }
}

export const cartEmphasizePromotionAll = (ids = [], options = {}) =>{
  ids.forEach((id, index) => {
    if (index > 0) options.scroll = false // 只滚动到第一个活动
    cartEmphasizePromotion(id, options)
  })
}
export const cartEmphasizePromotion = (id, options = {}) =>{

  const { shake = false, changeColor = false, scroll = false } = options
  const containerDom = document.querySelector(`.j-promotion-header-${id}`)
  if (!containerDom) return
  let PAGE_HEAD_HEIGHT = 146
  let topTplH = document.querySelector('.j-top-tpl')?.getBoundingClientRect()?.height || 0
  let $bagTitleH = document.querySelector('.j-shopbag-title')?.getBoundingClientRect()?.height || 0
  const nowScrollTop = window.scrollY
  const containerTop = containerDom.getBoundingClientRect().top
  const cartHeaderHeight = topTplH + $bagTitleH + PAGE_HEAD_HEIGHT

  // 是否滚动到标题处
  if (scroll) {
    window.scrollTo({
      top: nowScrollTop + containerTop - cartHeaderHeight,
      behavior: 'smooth'
    })
  }

  // 颜色变红
  if (changeColor) {
    setTimeout(() => {
      const $target = containerDom.querySelector('.cart_item-tip')
      $target && $target.classList.add('cart-red-dark')
    }, 50)
  }

  // 是否抖动
  if (shake) {
    const $target = containerDom.querySelector('.cart_item-banner')
    $target && $target.classList.add('promotion-header-shake')
    setTimeout(() => {
      $target && $target.classList.remove('promotion-header-shake')
    }, 1000)
  }
}

const flashSaleAndDiscountLimitHandle = (goods = [])=>{
  let obj = {
    orderTotalProductSize: 0,
    skcLimitNum: {}
  }
  goods.forEach(item => {
    obj.orderTotalProductSize += item.quantity
    // 同类商品skc不同尺码sku加起来的数量
    obj.skcLimitNum[item.product.goods_sn] = (obj.skcLimitNum[item.product.goods_sn] || 0) + item.quantity
  })
  return obj
}
// 获取商品行的限购数量
const getItemLimitNum = ({ single, skcLimitNum, product }) => {
  let itemLimitNum = 0
  const { is_checked } = product
  if (is_checked) {
    itemLimitNum = skcLimitNum[product.product.goods_sn] ? single - (skcLimitNum[product.product.goods_sn] - product.quantity) : single
  } else {
    itemLimitNum = skcLimitNum[product.product.goods_sn] ? single - skcLimitNum[product.product.goods_sn] : single
  }
  itemLimitNum = itemLimitNum <= 0 ? -1 : itemLimitNum
  return itemLimitNum
}

/**
 *
 * @param {object} product 购物车商品信息 不传则计算总数skc
 * @param {object} itemPromotionGroupInfo 商品所在促销组信息
 * @param {boolean} isOver 判断是超出还是满足或超出 默认false满足或超出 点击checkout校验时传true
 * limit_code:0超限拦截 1超限按原价
 * @param {boolean} isDetail 是否返回计数详情, 用于兼容旧的调用方式, 默认为false, 传true时, 将以对象的形式返回计数详情及超限判断结果
 * @return {*|boolean}
 */
export const handleOrderOrSingleIsMeetOrOver = (product = {}, itemPromotionGroupInfo = {}, isOver, isDetail = false) => {
  let result = 0
  let flashSaleAndDiscountLimitHandleInfo
  // 商品行的限购
  let itemLimitNum = 0
  if(([10].includes(+itemPromotionGroupInfo.type_id) && itemPromotionGroupInfo.limit_mode != 1) || [11].includes(+itemPromotionGroupInfo.type_id)){
    let single_over = false
    let order_over = false
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList)
    let { member, single, order } = itemPromotionGroupInfo.cart_limit || {}
    if(+single){
      if(!product) {
        let flashSaleSkcLimitNum =  Object.values(flashSaleAndDiscountLimitHandleInfo.skcLimitNum)
        single_over = flashSaleSkcLimitNum.find(item => item > single) ? true : false
      } else {
        single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
        itemLimitNum = getItemLimitNum({ single, skcLimitNum: flashSaleAndDiscountLimitHandleInfo.skcLimitNum, product })
      }
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order) {
      order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
    }
    result = parseInt(`${+Boolean(order_over)}${+Boolean(single_over)}`, 2)
  }
  // 打折限购或一口价
  if([24, 30].includes(+itemPromotionGroupInfo.type_id)){
    let proGroupList = itemPromotionGroupInfo.list?.filter(item => item.is_checked) || []
    flashSaleAndDiscountLimitHandleInfo = flashSaleAndDiscountLimitHandle(proGroupList, true)
    let { single, order, member } = itemPromotionGroupInfo.cart_limit || {}
    // 同类商品不同尺码加起来的数量超过该商品的限购数时
    if(+single){
      const single_over = isOver ? flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] > single : flashSaleAndDiscountLimitHandleInfo.skcLimitNum[product.product.goods_sn] >= single
      single_over && (result = 1)
      itemLimitNum = getItemLimitNum({ single, skcLimitNum: flashSaleAndDiscountLimitHandleInfo.skcLimitNum, product })
    }
    let cart_limit_order = 0
    if(+member && +order){
      cart_limit_order = Math.min(+member, +order)
    }else if( +order || +member ){
      cart_limit_order = +member || +order
    }
    if(cart_limit_order){
      const order_over = isOver ? flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize > cart_limit_order : flashSaleAndDiscountLimitHandleInfo.orderTotalProductSize >= cart_limit_order
      order_over && (result = 2)
    }
  }
  if(isDetail) return { result, flashSaleAndDiscountLimitHandleInfo, itemLimitNum }
  else return result
}

// 根据allDiff判断取value还是valueAmount
export const handleRangeText = (allDiff = {}, toast) => {
  const { type, value, value_amount } = allDiff
  const difference = type == 1 ? value : (value_amount?.amountWithSymbol || '')
  return toast ? difference : `<span class="add-buys-price">${difference}</span>`
}

export const quantityCheck = (e) => {
  if (e?.target?.value) {
    e.target.value = e.target.value.replace(/\D/g, '')
  }
}

export const scrollToCartItem = (cartId, highlightAnimate = false) => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  if(cartId) {
    setTimeout(()=>{
      let $target = document.querySelector(`[data-cart-id="${cartId}"]`)
      if(!$target) return
      const { top, height } = $target.getBoundingClientRect()
      const elCenter = top + height / 2
      const center = window.innerHeight / 2
      window.scrollTo({ top: scrollTop - (center - elCenter), behavior: 'smooth' })
      if(highlightAnimate) {
        if($target) {
          const excuteAnimate = _.debounce(function() {
            triggerHighlightAnimation($target, [cancelListener])
          }, 300)
          function cancelListener() {
            window.removeEventListener('scroll', excuteAnimate)
          }
          window.addEventListener('scroll', excuteAnimate)
        } else {
          // eslint-disable-next-line no-console
          console.log('.mshe-item not found')
        }
      }
    }, 500)
  }
}

function triggerHighlightAnimation(element, callbacks) {
  callbacks.forEach(cb => cb())
  // 添加高亮动画类
  element.classList.add('highlight-animate')
  // 让浏览器有时间渲染动画，然后移除类以便之后可以重新触发动画
  setTimeout(() => {
    element.classList.remove('highlight-animate')
  }, 800) // 动画总时间是 800ms
}

export const buildMallFullCourtMap = (integrityPromotionInfo = {}, cartsInfo = {}) => {
  let mallMap = {}
  let singleMallFullPromotionList = Object.values(integrityPromotionInfo).filter(promo => ([21, 26].includes(+promo.type_id) || ([14].includes(+promo.type_id) && promo.goods?.type == 3)))
  singleMallFullPromotionList.forEach(item => {
    if(!mallMap[item.mall_code]){
      mallMap[item.mall_code] = []
    }
    // 全场返券进度条
    let processCount = 0
    if (item.type_id == 21) {
      const diffPrice = +item?.promotion_range_info?.diff?.value || 0
      if (!diffPrice) {
        processCount = 100
      } else {
        const totalPrice = +cartsInfo?.mall_list?.find(val => val.mall_code == item.mall_code)?.total_price?.amount || 0
        processCount = parseInt((1 - (diffPrice / (diffPrice + totalPrice))) * 100)
      }
    }

    const fullCourtItem = {
      promotion_id: item.promotion_id,
      typeId: item.type_id,
      tip: item.content?.tip || '',
      countDownTimeStamp: +item.is_count_down ? item.end_time : '',
      add: item.content?.action,
      priority: +item.is_count_down && item.content?.action ? 1 : item.content?.action ? 2 : +item.is_count_down ? 3 : 4,
      diff_us_amount: item.content?.diffAmount ? item.content.diffAmount.usdAmount || 0 : 0,
      processCount: processCount,
    }

    if (item.type_id == 21) {
      Object.assign(fullCourtItem, {
        couponNum: item.coupon_num,
        allFullReduceFlag: item.all_full_reduce_flag,
        totalValue: item.total_value,
        coupons: item.coupons,
        lable_id: item.lable_id, // 推荐选品id
      })
    }

    mallMap[item.mall_code].push(fullCourtItem)
  })
  /*mallMap['1'] = []
  mallMap['1'].push({
    promotion_id: 1111,
    typeId: 21,
    tip: 'test <em>21</em> promotion tips hahahahahahaha add on items test 21 promotion tips hahahahahahaha add on items test 21 promotion tips hahahahahahaha add on items',
    countDownTimeStamp: Date.now() + 10000000,
    add: 1,
  })
  mallMap['1'].push({
    promotion_id: 2222,
    typeId: 26,
    tip: 'test 26 promotion tips hahahahahahaha return coupon',
    countDownTimeStamp: Date.now() + 9000000,
    add: 1,
  })
  mallMap['1'].push({
    promotion_id: 3333,
    typeId: 14,
    tip: 'test 14 promotion tips hahahahahahaha full substrict',
    countDownTimeStamp: Date.now() + 8000000,
    add: 1,
  })*/
  Object.keys(mallMap).forEach(key => mallMap[key].sort((a, b)=> a.priority - b.priority))
  return mallMap
}

/**
 * 判断购物车中是否存在某促销活动
 * @param {Array} carts 购物车商品
 * @param {Array} activityTypeIds 促销活动typeId
 * @return activity_method 0存在 1不存在
 */
export const judgeActivityIsExist = (carts = [], activityTypeIds = []) => {
  let activity_method = 1
  carts.forEach(item => {
    let promotionInfo = item?.product?.product_promotion_info
    promotionInfo?.forEach(info => {
      if (activityTypeIds.includes(+info.type_id)) {
        activity_method = 0
      }
    })
  })
  return activity_method
}

/**
 * 获取用于埋点的 goods_list 参数
 * @param {Array} carts 需要上报的购物车列表
 * @param {Array, String} filterIds 用于过滤的cartId
 * @return {String}
 */
export const getGoodsListForAnalysis = ({ carts = [], filterIds = [] } = {}) => {
  let result = ''
  let exposeList = carts
  filterIds = [].concat(filterIds)
  // 过滤
  if (filterIds && filterIds.length > 0) {
    exposeList = carts.filter(item => filterIds.includes(item.id))
  }

  exposeList.forEach((item, index) => {
    const goodsId = item.product.goods_id
    const skuId = item.product.goods_sn
    const spuId = item.product.productRelationID
    const page = 1
    const operation = 1
    const recMark = ''
    const price = item.product.feSalePrice.usdAmount
    const originPrice = item.product.feOriginalPrice.usdAmount
    result += `,${goodsId}\`${skuId}\`${spuId}\`${+index + 1}\`${page}\`${operation}\`${recMark}\`\`pri_${price}|pri_${originPrice}\`\`\`mall_${item.mall_code}` // goods_list: goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识rec_mark
  })

  return result.slice(1)
}

/**
 * 和找相似和重选有关: 购物车多处用到了, 统一先放到这
 * @param {Object} item 购物车商品行
 * @param {Boolean} isPlatForm 是否平台化mall
 * @return {Boolean}
 */
export const isValidCartItem = (item, isPlatform = false) => {

  if(item.status == -1 && !isPlatform) { // 当前单mall站点没有该mall商品 直接失效展示'找相似'
    return false
  }
  if(item.status == 2 && [0, 2, 3, 4].includes(+item.promotion_status)){
    return false
  }
  const isNoRelated = Array.isArray(item.relatedProductIds) ? !item.relatedProductIds.length : !item.relatedProductIds  // 无关联商品
  if(!isNoRelated){ // 有关联商品直接可编辑
    return true
  }
  
  if (isPlatform && item.mall_code_list?.length > 0) { // 其他mall可售
    return true
  }
  const isNoEnough = item.product?.stock == 0 && +item.realTimeInventory < +item.quantity // 无关联商品且商品总库存为0
  const isNoOnSale = item.product?.is_on_sale == 0 // 无关联商品且商品已下架
  const noMainSaleAttrs = item.product?.sku_sale_attr && item.product?.sku_sale_attr?.filter(item => !item.is_main && item.attr_value_id) || [] // 新sku属性结构 过滤掉主属性后的尺码 attr_value_id ==0为无尺码 反之为有尺码
  let oldSingleOrOneSize = (!item.attr || item.attr?.attr_value_en == 'one-size' || item.attr?.attr_value_id === 0 )
  const isNoOtherAttrs = (oldSingleOrOneSize || (item.product.sku_code && !noMainSaleAttrs.length)) // 无关联商品且商品无其他尺码

  return !(isNoEnough || isNoOnSale || isNoOtherAttrs)
}

export const getMainImageUrl = ({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains }) => {
  if (item.isBlindBoxFlag) {
    return transformImg({ img: 'http://img.ltwebstatic.com/images3_acp/2024/11/22/24/1732274754479c270973cb429359284ea52acad318.png' })
  } else {
    const isCustomGood = Boolean(Object.keys(item.product?.customization_info || {}).length)
    const { dprInsert } = dprInsertMixins.methods || {}
    if(isCustomGood && !IS_RW) {
      const effectUrl = dprInsert({ img: item.product?.customization_info?.preview?.effects?.[0] || item.product?.goods_img || '', customServiceImgDomains, thirdPartyServiceImgDomains }) // effects 为空则不做替换
      return transformImg({ img: effectUrl })
    } else {
      return transformImg({ img: item.product?.goods_img })
    }
  }
}

export const getAddOnItemStatus = (promotion, cartList = [], soldoutIds = []) =>{
  const { type_id, promotion_id } = promotion
  const promotionProductTypeMap = {
    4: '1',
    2: '1',
    28: '1',
    22: '2',
    13: '2',
  }

  let onSaleList = cartList.filter(item => soldoutIds.indexOf(item.id) == -1)
  const item = onSaleList.find(item => 
    item.product?.product_promotion_info?.some(p => 
      p.promotion_product_type == promotionProductTypeMap[type_id] && p.promotion_id == promotion_id
    )
  )

  return {
    isPicked: !!item,
    isChecked: !!item?.is_checked,
  }
}

/**
 * 乱序数组 Fisher–Yates
 * @param {Array}
 * @return {Array}
 */
export const shuffleArr = (arr) => {
  const len = arr.length - 1
  for(let i = len; i > 0; i--){
    const j = Math.floor(Math.random() * i)
    const temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  }
  return arr
}

/**
 * 判断元素是否在可视区域
 */
export const isInViewPort = (element, isIntersecting = false) => {
  if (!element) return
  const viewHeight =
    window.innerHeight || document.documentElement.clientHeight
  const { top, bottom, height } = element.getBoundingClientRect()
  const EXPOSURE_HEIGHT = height * 0.6
  const EXTRA_HEIGHT = 800

  let isInViewPort = false
  if (height === 0) {
    return false
  }
  if (isIntersecting) {
    isInViewPort =
      (top >= 0 && top <= viewHeight + EXTRA_HEIGHT) ||
      (top < 0 && bottom >= -EXTRA_HEIGHT)
  } else {
    isInViewPort =
      (top >= 0 && bottom <= viewHeight + EXPOSURE_HEIGHT) ||
      (top < 0 && bottom >= EXPOSURE_HEIGHT)
  }

  return isInViewPort
}

/**
 * 判断当前活动是否是新人返券活动
 * @param {*} promotionInfo 活动信息
 */
export const isNewUserReturnCoupon = (promotionInfo) => {
  if (typeof window === 'undefined') return false
  const { NEW_USER_COUPON_TYPE_ID = 0 } = gbCommonInfo || {}
  return promotionInfo?.typeId == 21 && promotionInfo?.coupons?.length && promotionInfo?.coupons?.every(item => item?.type_id == NEW_USER_COUPON_TYPE_ID)
}

/**
 * 判断是否是数字或者数字字符串
 * @param {*} value 
 * @returns 
 */
const isNumberOrString = (value) => (typeof value === 'number' || typeof value === 'string') && !isNaN(Number(value))

/**
 * 获取QuickShip标签的样式和文案
 * @param {*} abtInfo abt
 * @param {*} language 多语言文案
 */
export const getQuickShipTagByAbt = (abtInfo = {}, language = {}) => {
  const { CartQuickshipVisual = {} } = abtInfo
  const { SHEIN_KEY_PC_23485, SHEIN_KEY_PC_30515 } = language
  return {
    text: isNumberOrString(CartQuickshipVisual?.param?.cart_quickship_visual) ? template(CartQuickshipVisual.param.cart_quickship_visual, SHEIN_KEY_PC_30515) : SHEIN_KEY_PC_23485,
    isShowNewStyle: CartQuickshipVisual?.param?.cart_quickship_visual && (CartQuickshipVisual.param.cart_quickship_visual === 'quickship' || isNumberOrString(CartQuickshipVisual.param.cart_quickship_visual)),
  }
}

/**
 * 判断是否30天低价
 */
export const isThirtyLowestPrice = (tsp, item) => {
  // 取商品当前价格为 y， 如果有特价，则取特价、如果没有特价，则取原价；
  // 当商品命中新人闪购、特殊闪购、付费会员、S3会员，券后价，默认取原价为 y；
  const toArr = arr => Array.isArray(arr) ? arr : [arr]
  const promotionInfo = toArr(item?.product?.product_promotion_info || item?.promotionInfo || {}) 
  const isHit = type => promotionInfo.find(item => item.typeId == type || item.type_id == type)
  const isVip = isHit('29') // 付费会员
  const isS3 = isHit('12') // S3 会员
  const flashPromotion = isHit('10')
  const isFlash = flashPromotion && [2, 3].includes(item.flashType || flashPromotion.flashType || flashPromotion.flash_type ) // 闪购

  // 取值逻辑
  // 购物车： 原价 product?.retailPrice，销售价 unitPrice 
  // 其他地方: 原价 retail_price 或 retailPrice，销售价 sale_price 或 salePrice
  const amount = isFlash || isVip || isS3
    ? (item.product?.feOriginalPrice?.amount || item.retail_price?.amount || item.retailPrice?.amount)
    : (item.unit_price_before_coupon?.amount || item.sale_price?.amount || item.salePrice?.amount) 
  
  const d1 = tsp?.['4018'] || ''
  const x1 = +(tsp?.['3864'] || ''),
        z1 = +(tsp?.['4015'] || '')
  const x2 = +(tsp?.['3865'] || ''),
        z2 = +(tsp?.['4016'] || '')
  const x3 = +(tsp?.['3866'] || ''),
        z3 = +(tsp?.['4017'] || '')

  const result = {
    30: amount <= x1 && amount < z1 && d1 > 1 ? 30 : false,
    60: amount <= x2 && amount < z2 && d1 > 1 ? 60 : false,
    90: amount <= x3 && amount < z3 && d1 > 1 ? 90 : false,
  }

  // 过去30天最低价：3864 | 过去30天最高价：4015 | 是否调价: 4018
  return result
}

/**
 * 获取商品销售属性文案
 */
export const getItemSaleText = ({ defaultlocalsize, skuSaleAttr, sizeRuleList, userLocalSizeCountry }) => {
  const formatStrategy = {
    old: {
      cartSize: ({ defaultAttr, localAttr }) => {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    new: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        if (localAttr) {
          return `${localAttr}(${defaultAttr})`
        }
        return defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    local_size_no_default: {
      cartSize: ({ defaultAttr, localAttr }) => {
        return localAttr || defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
    default_size_no_default: {
      cartSize: ({ defaultAttr }) => {
        return defaultAttr
      },
      localSize({ defaultAttr, localAttr }) {
        return localAttr || defaultAttr
      },
      defaultSize({ defaultAttr }) {
        return defaultAttr
      }
    },
  }

  const executeStrategy = () => {
    const strategy = formatStrategy[defaultlocalsize] ?? formatStrategy.old
    const handleStrategy = userLocalSizeCountry ? userLocalSizeCountry === 'default' ? strategy.defaultSize : strategy.localSize : strategy.cartSize
    const getSizeMap = (attrSize = []) => attrSize?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {})
    const attrSizeMap = getSizeMap(sizeRuleList)
    return skuSaleAttr?.map(item => {
      const text = item.is_main != 1
        ? handleStrategy({
          defaultAttr: item.attr_value_name,
          localAttr: attrSizeMap[item.attr_value_name_en]?.correspond,
        })
        : item.attr_value_name
      return text
    }) ?? []
  }

  return executeStrategy()
}


/**
 * 获取折扣文案
 */
export const getDiscountText = (lang, GB_cssRight, discount) => {
  if (discount) {
    const symbol = lang != 'il' ? '-' : ''
    return GB_cssRight ? `%${discount}${symbol}` : `${symbol}${discount}%`
  }
  return ''
}
